import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { refreshWeather, getIconColor, iconString } from '../utils/weather';
import { Clock } from '../utils/Countdown';
import Linkbutton from '../components/Linkbutton';
import { SwiperSlides } from '../components/SwiperSlides';
import { analysis, audit, shipping, cash, sun, clock } from '../images/images.js';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function Home() {
	const MINUTE_MS = 60000;
	const today = moment().format('L');
	const day = moment().format('dd');
	const { weather: weatherData, zone: zoneInfo, hq, userName, userSettings, weatherUpdate: latestUpdate } = useSelector((state) => state.users);
	const [weatherList, setWeatherList] = useState(weatherData.slice(0, 10));
	const [visibleManual, setVibileManaul] = useState([]);
	const [hasMore, setHasMore] = useState(true);

	const manualList = [
		{ page: '配送表', link: 'https://coda.io/d/_dVpFBL90ZAO/_suh93#_ludYF', url: shipping, width: '50%' },
		{ page: '自檢表', link: 'https://coda.io/d/_d4ygeoE1_gJ/_suCNo#_luvxj', url: audit, width: '50%' },
		{ page: '數據統計', link: 'https://coda.io/d/_dVpFBL90ZAO/_suh93#_ludYF', url: analysis, width: '50%' },
		{ page: '每日收支', link: 'https://coda.io/d/_dl9Kvla70Wc/_suBFc#_luuOo', url: cash, width: '50%' },
	];

	useEffect(() => {
		getPermission();
		refreshWeather(userName, weatherData, hq, zoneInfo, setWeatherList);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			refreshWeather(userName, weatherData, hq, zoneInfo, setWeatherList);
		}, MINUTE_MS);

		return () => clearInterval(interval);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [weatherData]);

	const getPermission = async () => {
		let route = userSettings.filter((f) => f.permission[zoneInfo].includes(userName));
		route = route.map((r) => r.section);
		const visible = manualList.filter((m) => route.includes(m.page)).map((m, idx) => RenderCard(m, idx));
		setVibileManaul(visible);
	};

	function fetchMoreData() {
		setTimeout(() => {
			setWeatherList((prev) => {
				let index = prev.length;
				prev = prev.concat(weatherData.slice(index, index + 10));
				if (prev.length === weatherData.length) {
					setHasMore(false);
				}
				return prev;
			});
		}, 500);
	}

	const RenderCard = (manual, index) => (
		<Card sx={{ maxWidth: '100vw', height: 80 }} key={index}>
			<Stack direction='row' alignItems='center'>
				<img src={manual.url} alt='time' style={{ width: '40%', height: '35%', margin: 5, padding: 2 }} />
				<Linkbutton sx={{ fontSize: 12 }} link={manual.link} text={`查看${manual.page}手冊`}></Linkbutton>
			</Stack>
		</Card>
	);

	const WeatherContent = ({ weather, singleStore, firstRow }) => {
		const weatherVal = singleStore ? weather : weather.current;
		return (
			<>
				<Grid item md={3} xs={4} p={2} textAlign='left'>
					<Typography>{singleStore ? (firstRow ? moment(weather.time, 'MM/DD/YYYY').format('YYYY-MM-DD') : weather.time.split(' ')[0]) : weather.store_name}</Typography>
				</Grid>
				<Grid item md={3} xs={2} py={2}>
					<Typography fontSize={{ xs: 14, md: 16 }}>{parseInt(weatherVal.temp) + `\u00B0C`}</Typography>
				</Grid>
				<Grid item md={3} xs={2.5} py={2}>
					<Stack direction={'row'} display={'inline-flex'}>
						<Typography fontSize={{ xs: 14, md: 16 }} pr={1}>
							{weatherVal.description}
						</Typography>
						<img src={iconString(weatherVal.icon)} height='30' width='30' style={{ backgroundColor: getIconColor(weatherVal.icon), borderRadius: 20 }} alt='weatherIcon' />
					</Stack>
				</Grid>
				<Grid item md={3} xs={3.5} p={2}>
					<Typography fontSize={{ xs: 14, md: 16 }}>{parseInt(weatherVal.feels_like) + `\u00B0C`}</Typography>
				</Grid>
			</>
		);
	};

	return (
		<>
			<Grid container>
				<Grid item md={12} pb={1} pt={2}>
					<Card sx={{ alignContent: 'center', minHeight: 410, maxWidth: '92vw' }}>
						<CardContent>
							<Grid container>
								<Grid item md={3} xs={12} alignSelf='center'>
									<img src={clock} alt='time' style={{ width: '40%', height: '40%' }} />
									<Typography sx={{ fontSize: 16, fontWeight: 'bold' }} color='text.secondary' gutterBottom>
										{today} ({day})
									</Typography>
									<Typography sx={{ fontSize: 20, fontWeight: 'bold' }} gutterBottom>
										<Clock />
									</Typography>
								</Grid>
								<Grid item md={9} xs={12} pt={{ md: 0, xs: 1 }} pl={{ md: 1, xs: 0 }}>
									<Card sx={{ alignContent: 'center', maxHeight: 600 }}>
										<CardContent>
											<Stack direction='row' justifyContent='center' alignItems='center'>
												<Typography fontWeight={'bold'}>門店天氣</Typography>
												<img src={sun} alt='weather' style={{ width: '5%', height: '5%' }} />
											</Stack>
											<Grid container sx={{ backgroundColor: '#e8e8e8' }}>
												<Grid item md={3} xs={4}></Grid>
												<Grid item md={3} xs={2}>
													溫度
												</Grid>
												<Grid item md={3} xs={2.5}>
													概況
												</Grid>
												<Grid item md={3} xs={3.5}>
													體感溫度
												</Grid>
											</Grid>
											<Grid container sx={{ '& .infinite-scroll-component__outerdiv': { width: '100%' } }}>
												{weatherData.length > 1 ? (
													<InfiniteScroll dataLength={weatherList.length} next={fetchMoreData} hasMore={hasMore} loader={<Typography>載入中...</Typography>} height={250} endMessage={<Typography>沒有更多資料</Typography>}>
														{weatherList.map((w) => (
															<Box style={{ borderBottomStyle: 'inset', borderBottomColor: '#e0e0e0', display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }} key={w.sid + w.store_name}>
																<WeatherContent weather={w} singleStore={false} firstRow={false} />
															</Box>
														))}
													</InfiniteScroll>
												) : weatherData.length === 1 ? (
													<Box style={{ borderBottomStyle: 'inset', borderBottomColor: '#e0e0e0', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', width: '100vw' }} key={weatherData[0].id}>
														<WeatherContent weather={weatherData[0].current} singleStore={true} firstRow={true} />
														{weatherData[0].forecast.map((f, i) => {
															if (i === 0 || i === 3 || i === 6) {
																return <WeatherContent weather={f} singleStore={true} firstRow={false} key={f.feels_like + f.time} />;
															}
															return '';
														})}
													</Box>
												) : (
													<Typography textAlign='center'>載入資料中...</Typography>
												)}
											</Grid>
										</CardContent>
										<Box display='flex' justifyContent='space-between' pl={2} pr={2} pb={1}>
											<Typography variant='caption' display='flex' flexDirection='row-reverse'>
												資料來源：OpenWeather
											</Typography>
											<Typography variant='caption' display='flex' flexDirection='row-reverse'>
												最後更新時間：{latestUpdate}
											</Typography>
										</Box>
									</Card>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
			<div>
				<SwiperSlides perView={1} space={10} breakpoints={{ 640: { slidesPerView: 1, spaceBetween: 20 }, 768: { slidesPerView: 3, spaceBetween: 20 }, 1024: { slidesPerView: 3, spaceBetween: 20 } }} slides={visibleManual} />
			</div>
		</>
	);
}
