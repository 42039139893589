import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { API } from 'aws-amplify';
import { onCreateDeliveryData, onUpdateDeliveryData } from '../graphql/subscriptions';
import {
	getFilterString,
	getQueryString,
	getQueryObj,
	getDeliveryLists,
	getToken,
	getStore,
} from '../utils/requestDelivery';
import { getStoreIds } from '../utils/extra';
import { refreshWeather } from '../utils/weather';
import WeatherTop from './WeatherTop';
import Dashboard from './Dashboard';
import Delivery from './Delivery';
import Done from './Done';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import FeaturedPlayListRoundedIcon from '@mui/icons-material/FeaturedPlayListRounded';

export default function Shipping() {
	const MINUTE_MS = 60000;
	const userVal = useSelector((state) => state.users);
	const userLogged = userVal.storeName;
	// const storeIDs = userVal.value.attributes.nickname.split(',');
	const zoneInfo = userVal.zone;
	const hq = userVal.hq;
	const userName = userVal.userName;
	const storeid = hq ? null : userLogged.split(',')[1];
	const weatherData = userVal.weather;
	const [storeIDs, setStoreIDs] = useState([userName]);
	const [onload, setOnload] = useState(true);
	const [info, setInfo] = useState([]);
	const [token, setToken] = useState('');
	const [user, setUser] = useState('');
	const [pendingList, updatePendingList] = useState([]);
	const [progressList, updateProgressList] = useState([]);
	const [doneList, updateDoneList] = useState([]);
	const [route, setRoute] = useState('Dashboard');
	const [index, setIndex] = useState(0);
	const [curSid, setCurSid] = useState(hq ? '' : userName);
	const qDate = moment().format('YYYYMMDD');
	const queryFilter = getQueryString();
	const queryMerge = getFilterString(storeid, false, false);
	const variables = { filter: getQueryObj(storeid, qDate) };
	const orderstate = ['hide', 'merged'];
	const pendingState = ['pending', 'canceled', 'returned'];

	const getInfo = () => {
		setUser(userLogged.split(',')[1]);
		onQuery();
		getStore(userLogged.split(',')[1]).then((res) => setInfo(...res));
		refreshWeather(userName, weatherData, hq, zoneInfo);
	};

	const onQuery = async () => {
		let sid = hq ? await getStoreIds(hq, zoneInfo, userName, '/shipping', 'id') : storeIDs;
		const reqstoreIDs = hq ? ['HQ'] : sid;
		getDeliveryLists(reqstoreIDs, qDate, queryFilter).then((res) => {
			if (hq && zoneInfo !== 'master' && sid.length >= 1) {
				res[0] = res[0].filter((item) => sid.includes(item.store_id));
			}
			res[0].sort(function (a, b) {
				if (a.createdAt > b.createdAt) {
					return -1;
				}
				if (a.createdAt < b.createdAt) {
					return 1;
				}
				return 0;
			});
			catgoryData(res[0]);
		});
		setStoreIDs(sid);
	};

	const onMergeUpdate = async () => {
		getDeliveryLists(storeIDs, qDate, queryMerge).then((res) => {
			res[0].sort(function (a, b) {
				if (a.createdAt > b.createdAt) {
					return -1;
				}
				if (a.createdAt < b.createdAt) {
					return 1;
				}
				return 0;
			});
			catgoryData(res[0]);
		});
	};

	const catgoryData = (data) => {
		let pending = [];
		let progress = [];
		let done = [];
		data.map((i) => {
			if (i.deliveryInfo) {
				i.open = false;
				i.deliveryInfo = i.deliveryInfo.map((item) => JSON.parse(item));
				if (i.status === 'delivered') {
					done.push(i);
				} else {
					progress.push(i);
				}
			} else if (pendingState.includes(i.status)) {
				pending.push(i);
			}
			return i;
		});
		updatePendingList(pending);
		updateProgressList(progress);
		updateDoneList(done);
	};

	const updateSub = (prev, newVal) => {
		const updateVal = [...prev];
		let filterIndex = updateVal.findIndex((obj) => obj.id === newVal.id);
		if (filterIndex >= 0) updateVal[filterIndex] = newVal;
		return updateVal;
	};

	useEffect(() => {
		if (onload) {
			getToken().then((res) => setToken(res));
			getInfo();
			setOnload(false);
		}

		const sub = API.graphql({
			query: onCreateDeliveryData,
			variables,
		}).subscribe({
			next: ({ value }) => {
				let newVal = value.data.onCreateDeliveryData;
				let subData = zoneInfo === 'master' ? true : storeIDs.includes(newVal.store_id);
				if (!orderstate.includes(newVal.status) && !newVal._deleted && subData) {
					updatePendingList((prev) => [newVal, ...prev]);
				}
			},
			error: (error) => console.warn(error),
		});

		const sub2 = API.graphql({
			query: onUpdateDeliveryData,
			variables,
		}).subscribe({
			next: ({ value }) => {
				const newVal = value.data.onUpdateDeliveryData;
				const dInfo = newVal.deliveryInfo;
				let subData = zoneInfo === 'master' ? true : storeIDs.includes(newVal.store_id);
				if (!orderstate.includes(newVal.status) && !newVal._deleted && subData) {
					if (dInfo) {
						newVal.deliveryInfo = dInfo && dInfo.map((item) => JSON.parse(item));
						if (newVal.status === 'delivered') {
							updateDoneList((prev) => [newVal, ...prev]);
							updateProgressList((prev) => prev.filter((item) => item.id !== newVal.id));
						} else {
							updatePendingList((prev) => prev.filter((item) => item.id !== newVal.id));
							updateProgressList((prev) => {
								let val = [];
								const exists = prev.filter((item) => item.id === newVal.id);
								if (JSON.parse(dInfo[0]).courier || exists.length > 0) {
									val = prev.map((item) => (item.id === newVal.id ? newVal : item));
								} else {
									val = [newVal, ...prev];
								}
								return val;
							});
						}
					} else if (newVal.mace_status === '註銷') {
						updatePendingList((prev) => prev.filter((item) => item.id !== newVal.id));
					} else {
						updatePendingList((prev) => {
							let val = [];
							const checkType = prev.filter((item) => item.id === newVal.id);
							if (checkType.length === 0) {
								val = [newVal, ...prev];
								updateProgressList((prev) => prev.filter((item) => item.id !== newVal.id));
							} else {
								val = updateSub(prev, newVal);
							}
							return val;
						});
					}
				}
			},
			error: (error) => console.warn(error),
		});

		return () => {
			sub.unsubscribe();
			sub2.unsubscribe();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userLogged]);

	useEffect(() => {
		const interval = setInterval(() => {
			refreshWeather(userName, weatherData, hq, zoneInfo);
		}, MINUTE_MS);

		return () => clearInterval(interval);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [weatherData]);

	const routeComponents = [
		{
			comp: (
				<Dashboard
					user={user}
					storeid={storeid}
					dList={[...pendingList]}
					info={info}
					token={token}
					userSettings={userVal.userSettings}
					onMergeUpdate={onMergeUpdate}
					setCurSid={setCurSid}
				/>
			),
			route: 'Dashboard',
			icon: <FeaturedPlayListRoundedIcon sx={{ margin: 'unset' }} />,
			color: 'error',
			name: '待配送',
			count: pendingList.length,
		},
		{
			comp: <Delivery userLogged={userLogged} dList={[...progressList]} info={info} token={token} hq={hq} />,
			route: 'Delivery',
			icon: <DeliveryDiningIcon sx={{ margin: 'unset' }} />,
			color: 'primary',
			name: '配送中',
			count: progressList.length,
		},
		{
			comp: <Done userLogged={userLogged} dList={[...doneList]} />,
			route: 'Done',
			icon: <CheckCircleRoundedIcon sx={{ margin: 'unset' }} />,
			color: 'success',
			name: '已完成',
			count: doneList.length,
		},
	];
	const selectedComponent = routeComponents[index].comp || null;

	return (
		<>
			<Box sx={{ position: 'sticky', zIndex: 100 }}>
				<Grid container paddingBottom={2}>
					<Grid item md={12} xs={12} textAlign={'center'} paddingBottom={2}>
						<WeatherTop weatherData={weatherData} curSid={curSid} />
					</Grid>
					{routeComponents.map((item, index) => (
						<Grid item md={4} xs={4} display='flex' justifyContent='center' key={`sBtn-${index}`}>
							<Button
								sx={{ display: { xs: 'block', md: 'inline-flex' } }}
								variant={route === item.route ? 'contained' : 'outlined'}
								startIcon={item.icon}
								size='large'
								color={item.color}
								onClick={(e) => {
									setRoute(item.route);
									setIndex(index);
								}}>
								{item.name} {item.count}
							</Button>
						</Grid>
					))}
				</Grid>
				<Divider />
			</Box>
			<Box paddingTop={4}>{selectedComponent}</Box>
		</>
	);
}
