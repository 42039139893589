import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Dialog from '../components/Dialog';
import { geo } from '../utils/geo';
import { PaySwitch } from './util';
import { Countdown } from '../utils/Countdown';
import { saveAttr } from '../utils/lambda';
import { getCoordinate } from '../utils/requestDelivery';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Edit from '@mui/icons-material/Edit';
import Save from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import LocationOnIcon from '@mui/icons-material/LocationOn';

export default function Ordercard({ user, order, maxDelivery, userSettings, hideDelivery, qutoeDriver, getDriver, snackbar }) {
	const betaUser = userSettings.filter((i) => i.route === window.location.pathname)[0];
	const [list, setList] = useState({ ...order });
	const [edit, setEdit] = useState(false);
	const [readOnly, setReadOnly] = useState(true);
	const [place, setPlace] = useState([]);
	const [value, setValue] = useState(null);
	const [coordinate, setCoordinate] = useState(['23.553118', '121.0211024']);
	const [inputValue, setInputValue] = useState('');
	const [itemCount, setItemCount] = useState(0);
	const [itemTPrice, setItemTPrice] = useState(0);
	const [itemList, setItemList] = useState('');
	const [itemTList, setItemTList] = useState('');
	const [inputDis, setInputDis] = useState({ readOnly: true, disableUnderline: true });
	const [delBtn, setDelBtn] = useState(true);
	const [valid, setValid] = useState(false);
	const [warningMsg, setWarningMsg] = useState([]);
	const [state, setState] = useState(list.pay_state === '已支付');
	const [dialogState, setDialogState] = useState(false);
	const [dialogEdit, setDialogEdit] = useState(false);
	const [quoteCheck, setQuoteCheck] = useState(false);
	const progress = (parseInt(moment(moment(list.quote_expires).diff(moment())).format('mm')) * 60 + parseInt(moment(moment(list.quote_expires).diff(moment())).format('ss'))) / 10;
	const timeLeft = moment(moment(list.quote_expires).diff(moment())).format('mm:ss');
	Countdown(1000);

	const checkInput = async () => {
		let iCount = 0;
		let validVal = false;
		let warnMsg = [];
		let iList = '';
		let iTList = [];
		let priceitem = 0;
		let delBtnDis = order.quote_expires && moment() < moment(order.quote_expires) ? false : true;
		order.items.map((i, index) => {
			let val = i.split('@');
			iCount += parseInt(val[1]);
			priceitem += parseInt(val[2]) * 100;
			// prettier-ignore
			iTList.push({ 'name': val[0], 'quantity': parseInt(val[1]), 'price': parseInt(val[2]) * 100 });
			iList += index === 0 ? `${val[0]} x${val[1]}` : `, ${val[0]} x${val[1]}`;
			return '';
		});
		if (!order.dropoff_address.includes('號')) {
			validVal = true;
		} else if (order.pay_state === '未支付' && iCount > maxDelivery) {
			validVal = true;
		} else if (order.dropoff_name === '' || order.dropoff_address === '' || order.dropoff_address.length < 7 || order.dropoff_number === '' || isNaN(order.dropoff_number)) {
			validVal = true;
		} else if (iCount > maxDelivery) {
			validVal = true;
		}
		if (order.dropoff_name === '') {
			warnMsg.push('姓名');
		}
		if (order.dropoff_number === '' || isNaN(order.dropoff_number)) {
			warnMsg.push('電話');
		}
		if (!order.dropoff_address.includes('號') || order.dropoff_address === '' || order.dropoff_address.length < 7) {
			warnMsg.push('地址');
		}
		setDelBtn(delBtnDis);
		setItemCount(iCount);
		setItemTPrice(priceitem);
		setItemList(iList);
		setItemTList(iTList);
		setWarningMsg(warnMsg);
		setValid(validVal);
		let location = await getCoordinate(order.store_id);
		if (location) {
			setCoordinate(location);
		}
	};

	useEffect(() => {
		setList(order);
		checkInput();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order]);

	useEffect(() => {
		let active = true;
		if (!readOnly) {
			if (inputValue === '') {
				setPlace(value ? [value] : []);
				return undefined;
			}
			let newOptions = [];
			geo(inputValue.replaceAll(' ', ''), coordinate).then((results) => {
				if (active) {
					if (value) {
						newOptions = [value.replaceAll(' ', '')];
					}
					if (results) {
						newOptions = ['在Google搜尋..' + inputValue.replaceAll(' ', ''), ...results, '功能測試中...'];
					}
					setPlace(newOptions);
				}
			});
		}
		return () => {
			active = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value, inputValue]);

	const handleChange = (event, field) => {
		let newVal = { ...list };
		newVal[field] = event.target.value;
		setList({ ...newVal });
	};

	const toggle = (cancel) => {
		if (cancel) {
			const pay = order.pay_state === '已支付' ? true : false;
			setList({ ...order });
			setState(pay);
			setDialogEdit(false);
		}
		setReadOnly(!readOnly);
		setInputDis({ readOnly: edit, disableUnderline: edit });
		setEdit(!edit);
	};

	const handlePayState = (event) => {
		setState(event.target.checked);
		setTimeout(() => handleChange(event, 'pay_state', list.id), 500);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		const dropoffNotes = event.target[3].value === 'x' ? '-' : event.target[3].value;
		try {
			list.dropoff_name = event.target[0].value;
			list.dropoff_number = event.target[1].value;
			list.dropoff_address = event.target[2].value.replaceAll(' ', '');
			list.dropoff_notes = dropoffNotes;
			list.pay_state = state ? '已支付' : '未支付';
			await saveAttr(list);
			checkInput();
			toggle(false);
			snackbar('儲存成功!');
		} catch (error) {
			console.log('error on saving', error);
		}
	};

	const handleDialog = (clickQuote) => {
		if (clickQuote) {
			qutoeDriver(list, itemTPrice);
		}
		setDialogState(!dialogState);
		if (quoteCheck) {
			setQuoteCheck(!quoteCheck);
		}
	};

	const dContent = () => (
		<>
			<Typography variant='body1' fontWeight='bold' align='center' gutterBottom>
				請確認配送資訊是否正確，如要修改請按 [退出] 再按 [編輯]
			</Typography>
			<Stack direction='column' justifyContent='space-between'>
				<Typography fontSize={12} color='text.secondary'>
					訂單編號
				</Typography>
				<Typography variant='h5' gutterBottom>
					{list.id}
				</Typography>
				<Grid container>
					<Grid item xs={12} md={4}>
						<Typography fontSize={12} color='text.secondary'>
							支付狀態
						</Typography>
						<Typography variant='h5' color={list.pay_state === '未支付' ? 'error' : 'green'} gutterBottom>
							{list.pay_state}
						</Typography>
					</Grid>
					<Grid item xs={12} md={4}>
						<Typography fontSize={12} color='text.secondary'>
							訂購人姓名
						</Typography>
						<Typography variant='h5' gutterBottom>
							{list.dropoff_name}
						</Typography>
					</Grid>
					<Grid item xs={12} md={4}>
						<Typography fontSize={12} color='text.secondary'>
							訂購人電話
						</Typography>
						<Typography variant='h5' gutterBottom>
							{list.dropoff_number}
						</Typography>
					</Grid>
				</Grid>
				<Typography fontSize={12} color='text.secondary'>
					運送地址
				</Typography>
				<Typography variant='h5' gutterBottom>
					{list.dropoff_address}
				</Typography>
			</Stack>
			<FormControlLabel control={<Checkbox checked={quoteCheck} onChange={() => setQuoteCheck(!quoteCheck)} name='confirm' />} label='訂單確認' />
			<Typography variant='body1' color='error' fontWeight='bold'>
				*如果支付狀態有誤．須修改正確再進行預估費用及配送．否則會影響司機的接單意願！
			</Typography>
		</>
	);

	const dEditContent = () => (
		<Typography variant='body1' fontWeight='bold' align='center' gutterBottom>
			如要繼續編輯請按 [返回]
		</Typography>
	);

	return (
		<>
			<Card style={{ borderRadius: 15, marginTop: 20, marginBottom: 20, marginLeft: 20 }}>
				<CardContent>
					<Grid container style={{ textAlign: 'left' }} pl={2} pt={2}>
						<Grid item xs={12} md={3.3} paddingBottom={2}>
							<FormControl component='fieldset' variant='standard'>
								<FormGroup>
									<FormControlLabel control={<PaySwitch checked={state} onChange={(e) => handlePayState(e)} value={state ? '已支付' : '未支付'} disabled={!edit} />} label={<Typography>{state ? '已支付' : '未支付 [現金單]'}</Typography>} />
								</FormGroup>
							</FormControl>
							{user === 'HQ' ? (
								<>
									<Typography fontSize={12} color='text.secondary'>
										門市
									</Typography>
									<Typography variant='h5' gutterBottom>
										{list.pickup_name}
									</Typography>
								</>
							) : null}
							<Typography fontSize={12} color='text.secondary'>
								訂單來源
							</Typography>
							<Typography variant='h5' gutterBottom>
								{list.order_from}
							</Typography>
							<Typography fontSize={12} color='text.secondary'>
								日期
							</Typography>
							<Typography variant='h5' gutterBottom>
								{moment(list.createdAt).format('YYYY-MM-DD HH:mm')}
							</Typography>
							<Typography fontSize={12} color='text.secondary'>
								訂單編號
							</Typography>
							<Typography variant='h5' gutterBottom>
								{list.id}
							</Typography>
						</Grid>
						<Grid item md={1} paddingBottom={2} paddingRight={2} display={{ xs: 'none', md: 'block' }}>
							<Divider orientation='vertical' />
						</Grid>
						<Grid component='form' onSubmit={handleSubmit} container item xs={12} md={7.7} spacing={2} paddingBottom={2} sx={{ '& .MuiInputBase-input': { fontSize: '1.5rem' } }}>
							<Grid item xs={12} md={4}>
								<Typography fontSize={12} color='text.secondary'>
									訂購人姓名
								</Typography>
								<TextField fullWidth id={'name' + list.id} variant='standard' value={list.dropoff_name} onChange={(e) => handleChange(e, 'dropoff_name', list.id)} InputProps={inputDis} error={list.dropoff_name.length < 1} />
							</Grid>
							<Grid item xs={12} md={4}>
								<Typography fontSize={12} color='text.secondary'>
									電話 (盡量填寫手機，分機請填寫在備註)
								</Typography>
								<TextField fullWidth id={'phone' + list.id} variant='standard' value={list.dropoff_number} onChange={(e) => handleChange(e, 'dropoff_number', list.id)} InputProps={inputDis} error={list.dropoff_number.length < 7 || isNaN(list.dropoff_number)} />
							</Grid>
							<Grid item xs={12} md={4}>
								<Typography fontSize={12} color='text.secondary'>
									總杯數
								</Typography>
								<Typography variant='h5' gutterBottom>
									{itemCount}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography fontSize={12} color='text.secondary'>
									運送地址
								</Typography>
								{readOnly ? (
									<Typography variant='h5'>{list.dropoff_address}</Typography>
								) : JSON.parse(betaUser.beta).geo.includes(user) ? (
									<Autocomplete
										fullWidth
										id={'address' + list.id}
										value={list.dropoff_address}
										disablePortal
										options={place}
										getOptionDisabled={(x) => x === '功能測試中...'}
										getOptionLabel={(option) => (option.includes('Google') ? list.dropoff_address : option)}
										filterOptions={(x) => x}
										freeSolo
										onChange={(event, newValue) => setValue(newValue)}
										onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
										renderInput={(params) => <TextField {...params} variant='standard' error={valid} />}
										renderOption={(props, option) =>
											option.includes('Google') ? (
												<MenuItem key={props.id + 'google'} onClick={() => window.open(`https://www.google.com/maps/search/${inputValue}`, '_blank', 'noopener,noreferrer')}>
													<LocationOnIcon sx={{ color: 'text.secondary' }} />
													&nbsp;&nbsp;&nbsp;&nbsp;
													<Typography fontSize={14} color={'#1565c0'}>
														{option}
													</Typography>
												</MenuItem>
											) : (
												<li {...props}>
													<Grid container alignItems='center' key={props.id + Math.random()}>
														<Grid item sx={{ display: 'flex', width: 44 }}>
															<LocationOnIcon sx={{ color: 'text.secondary' }} />
														</Grid>
														<Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
															<Typography variant='body2' color='text.secondary' key={props.id + 'address'}>
																{option}
															</Typography>
														</Grid>
													</Grid>
												</li>
											)
										}
									/>
								) : (
									<TextField fullWidth id={'address' + list.id} variant='standard' value={list.dropoff_address} onChange={(e) => handleChange(e, 'dropoff_address', list.id)} InputProps={inputDis} error={valid} />
								)}
							</Grid>
							<Grid item xs={12}>
								<Typography fontSize={12} color='text.secondary'>
									備註
								</Typography>
								<Grid container spacing={2}>
									<Grid item xs={12} md={8} lg={9}>
										<TextField fullWidth multiline rows={3} id={'note' + list.id} variant='standard' value={list.dropoff_notes || '-'} onChange={(e) => handleChange(e, 'dropoff_notes', list.id)} InputProps={inputDis} />
									</Grid>
									<Grid item xs={12} md={4} lg={3} paddingTop={{ xs: 2 }}>
										<Button size='large' color='inherit' variant='outlined' startIcon={<Edit />} style={{ display: edit ? 'none' : undefined }} onClick={() => toggle(false)} disabled={list.quote_expires && moment() < moment(list.quote_expires) ? true : false}>
											編輯
										</Button>
										<Button size='large' type='submit' color='primary' variant='contained' startIcon={<Save />} style={{ display: edit ? undefined : 'none' }}>
											儲存
										</Button>
										<Button size='large' color='error' variant='outlined' startIcon={<UndoIcon />} onClick={() => setDialogEdit(true)} sx={{ display: edit ? undefined : 'none', marginTop: 1, '@media (max-width: 780px)': { marginTop: 0, marginLeft: 1 } }}>
											放棄
										</Button>
									</Grid>
								</Grid>
							</Grid>
							<Grid item container xs={12} sm spacing={2} alignItems={'center'}>
								<Grid item>
									<ErrorOutlineIcon color='error'></ErrorOutlineIcon>
								</Grid>
								<Grid item xs>
									<Typography fontSize={13} fontWeight='bold'>
										1. 訂購人須提供【手機號碼】才能接收配送簡訊
									</Typography>
									<Typography fontSize={13} fontWeight='bold'>
										2. 司機取件時，門市人員需簽名核對
									</Typography>
									<Typography fontSize={13} fontWeight='bold'>
										3. 請確認配送資訊是否正確，如要修改支付狀態等資訊請按 [編輯]
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Divider />
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header' style={{ textAlign: 'center' }} sx={{ '& .MuiAccordionSummary-content,.MuiAccordionSummary-contentGutters': { display: 'block' } }}>
							<Typography style={{ width: '100%', flexShrink: 0 }}>商品資訊 (${list.mace_total})</Typography>
						</AccordionSummary>
						<Divider />
						<AccordionDetails>
							<Typography>項目：{itemList}</Typography>
						</AccordionDetails>
					</Accordion>
				</CardContent>
				<Divider />
				<Grid
					container
					sx={{
						display: list.quote_expires && moment() < moment(list.quote_expires) ? 'flex' : 'none',
						alignItems: 'center',
						width: '100%',
					}}>
					<Grid item xs={12} sx={{ width: '100%', alignItems: 'center' }} paddingTop={3}>
						<Grid container>
							<Grid item md={4} xs={12}>
								<b>抵達門店:</b> 接單後約 {list.pickup_eta} 分鐘
							</Grid>
							<Grid item md={4} xs={12}>
								<b>送達顧客:</b> 預計 {moment(list.dropoff_eta).format('MM/DD HH:mm')}
							</Grid>
							<Grid item md={4} xs={12}>
								<b>預估運費:</b> ${list.fee * 0.01} {Math.ceil(itemCount / maxDelivery) > 1 ? '*' + Math.ceil(itemCount / maxDelivery) : null}(依實際為主)
							</Grid>
						</Grid>
					</Grid>
					<Grid item paddingTop={2} xs={12} md={10} sx={{ width: '100%', alignItems: 'center' }}>
						<LinearProgress variant='determinate' value={progress} />
					</Grid>
					<Grid item paddingTop={1} paddingBottom={1} xs={12} md={2} sx={{ width: '100%', alignItems: 'center' }}>
						<Typography sx={{ fontSize: '16px' }}>
							<b>預估運費時效:</b> {timeLeft}
						</Typography>
					</Grid>
				</Grid>
				<Divider />
				<CardActions sx={{ display: 'block' }}>
					<Typography sx={{ display: valid && itemCount < maxDelivery ? undefined : 'none' }} color='error' fontWeight='bold'>
						請填寫正確資訊: {warningMsg[0]}
						{warningMsg[1] && '、'}
						{warningMsg[1]}
						{warningMsg[2] && '、'}
						{warningMsg[2]}
					</Typography>
					<Stack direction='row' justifyContent='space-between' spacing={2} padding={1}>
						<Button variant='outlined' color='error' size='large' onClick={(e) => hideDelivery(e, list)}>
							不配送
						</Button>
						<Button variant='contained' color='inherit' size='large' onClick={() => handleDialog(false)} disabled={(list.quote_expires && moment() < moment(list.quote_expires)) || valid ? true : false}>
							預估費用
						</Button>
						<Button variant='contained' color='primary' size='large' onClick={() => getDriver(list, itemCount, itemTPrice, itemTList)} disabled={!(list.quote_expires && moment() < moment(list.quote_expires)) || delBtn}>
							配送
						</Button>
					</Stack>
					{itemCount / maxDelivery > 1 ? (
						<Typography marginRight={1} fontSize={14} color='text.secondary' paddingTop={1} sx={{ float: 'right' }}>
							*將需要{Math.ceil(itemCount / maxDelivery)}位司機進行配送
						</Typography>
					) : null}
				</CardActions>
			</Card>
			<Dialog dialog={dialogState} title={'訂單確認'} content={dContent()} cancel={'退出'} submit={'預估費用'} handleClose={() => handleDialog(false)} handleSubmit={() => handleDialog(true)} showBtn={true} showSub={true} disable={!quoteCheck} />
			<Dialog dialog={dialogEdit} title={'是否要放棄修改？'} content={dEditContent()} cancel={'返回'} submit={'放棄修改'} handleClose={() => setDialogEdit(false)} handleSubmit={() => toggle(true)} showBtn={true} showSub={true} />
		</>
	);
}
