import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import routes from './route';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

const getNav = (routes, userSettings, userLogged, userVal, mobile, pathname, navigate, handleDrawerClose) => {
	let navItems = 0;
	return routes.map((item, index) => {
		let hq = 'none';
		const curLvl = userSettings.filter((i) => i.route === item.route);
		const zoneInfo = userLogged.zoneinfo;
		const username = userVal.username;
		const permission = curLvl[0].permission[zoneInfo].map((item) => item.toLowerCase());
		if (permission[0] === 'all' || permission.includes(username)) {
			hq = undefined;
		}
		if (!item.hide) {
			if (mobile) {
				if (navItems < 6) {
					navItems += 1;
					return <BottomNavigationAction key={item.key + index} label={item.name} value={item.route} icon={item.icon} sx={{ display: hq }} />;
				}
			} else {
				return (
					<ListItemButton
						key={item.key + index}
						sx={{ minHeight: 48, justifyContent: 'center', px: 2.5, display: hq }}
						selected={pathname.startsWith(item.route)}
						onClick={(e) => {
							navigate(item.route, { state: { name: item.name } });
							handleDrawerClose();
						}}>
						<ListItemIcon sx={{ minWidth: 0, mr: 'auto', justifyContent: 'center', color: pathname.startsWith(item.route) ? '#fe182a' : 'inherit' }}>
							{item.icon}
							<Typography>&nbsp;{item.name}</Typography>
						</ListItemIcon>
					</ListItemButton>
				);
			}
		}
		return '';
	});
};

export const MainListItems = ({ handleDrawerClose }) => {
	const userLogged = useSelector((state) => state.users.value.attributes);
	const userVal = useSelector((state) => state.users.value);
	const userSettings = useSelector((state) => state.users.userSettings);
	const navigate = useNavigate();
	const { pathname } = useLocation();

	return (
		<React.Fragment>
			<List component='nav' sx={{ '& .css-1fqi8hc-MuiButtonBase-root-MuiListItemButton-root.Mui-selected, .css-1fqi8hc-MuiButtonBase-root-MuiListItemButton-root.Mui-selected:hover': { backgroundColor: '#fe182a14' } }}>
				{getNav(routes, userSettings, userLogged, userVal, false, pathname, navigate, handleDrawerClose)}
			</List>
		</React.Fragment>
	);
};

export const MobileListItems = () => {
	const userLogged = useSelector((state) => state.users.value.attributes);
	const userVal = useSelector((state) => state.users.value);
	const userSettings = useSelector((state) => state.users.userSettings);
	const navigate = useNavigate();
	const val = {
		'/order': '待配送',
		'/delivery': '配送中',
		'/done': '已完成',
		'/analysis': '數據統計',
		'/profit': '損益表',
		'/cash': '每日收支',
	};

	return (
		<React.Fragment>
			<BottomNavigation showLabels value={window.location.pathname} onChange={(event, newValue) => navigate(newValue, { state: { name: val[newValue] } })} sx={{ '& .Mui-selected, .Mui-selected > span': { color: '#fe182a !important' } }}>
				{getNav(routes, userSettings, userLogged, userVal, true)}
			</BottomNavigation>
		</React.Fragment>
	);
};

// export const secondaryListItems = (
//   <React.Fragment>
//     <ListSubheader component="div" inset>
//       Saved reports
//     </ListSubheader>
//     <ListItemButton>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Current month" />
//     </ListItemButton>
//     <ListItemButton>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Last quarter" />
//     </ListItemButton>
//     <ListItemButton>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Year-end sale" />
//     </ListItemButton>
//   </React.Fragment>
// );
